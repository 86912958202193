body {
  background-color: #e7edf5;
  min-width: 360px;
}
  
body.mini-navbar .nav-header {
  background-color: #053985;
}

body.fixed-sidebar #page-wrapper {
  margin: 0 0 0 250px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-darkblue {
  background-color: #039;
}

.bg-gray {
  background: #e7edf5;
}

.bg-gray2 {
  background-color: #f7f7f7;
}

.bg-green {
  background-color: #aadb5a;
}

.bg-gradient-orange {
  background: linear-gradient(to bottom, #e04403, #ffc107);
}

.bg-gradient-blue {
  background: linear-gradient(to bottom, #007bff, #a7c4d4);
}

.bg-gradient-green {
  background: linear-gradient(to bottom, #5ac219, #87e46f);
}

.br-26 {
  border-radius: 26px
}

.br-white {
  border-color: #fff;
}

.border-orange {
  border-color: #e04403 !important;
}

.border-green {
  border-color: #4cbb17 !important;
}

.border-darkblue {
  border-color: #039 !important;
}

.bg-orange {
  background-color: #e04403;
}

.bg-orange-btn:hover {
  background-color: #F5F5F5;
}

.bg-widget:hover {
  background-color: #e7edf5;
  cursor: pointer;
}

@media (max-width: 704px) {
  .btn-none {
    display: none;
  }
}

@media (min-width: 704px) {
  .btn-open {
    display: none;
  }
}

@media (max-width: 436px) {
  .btn-none-xs {
    display: none;
  }
}

@media (min-width: 436px) {
  .btn-open-xs {
    display: none;
  }
}

.btn-point {
  margin-right: 5px;
}

@media (max-width: 378px) {
  .btn-point {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}

@media (max-width: 450px) {
  .btn-point-md {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}

@media (max-width: 749px) {
  .btn-point-xl {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}

.blurred-image {
  filter: blur(5px);
}

.border-darkblue {
  border-color: #039; 
}

.border-orange {
  border-color: #e04403; 
}

.br-darkblue {
  border-color: #039;
}

.br-orange {
  border-color: #e04403;
}

.btn-darkblue {
  background-color: #039;
  color: #fff;
}

.btn-darkblue:hover {
  background-color: #039;
  color: #fff;
  opacity: 0.9;
}

.btn-green {
  background-color: #4cbb17;
  color: #fff;
}

.btn-green:hover {
  color: #fff;
  opacity: 0.8;
}

.btn-orange {
  background-color: #e04403;
  color: #fff;
}

.btn-orange:hover {
  background-color: #e04403;
  color: #fff;
  opacity: 0.9;
}

.cursor {
  cursor: pointer;
}

.color-gray {
  color: gray;
}

.d-inline-grid {
  display: inline-grid;
}

@media (max-width: 575px) {
  .grid {
    display: grid;
  }
}

@media (min-width: 956px) and (max-width: 1199px) {
  .grid {
    display: grid;
  }
}

/* @media (min-width: 972px) and (max-width: 992px) {
  .grid {
    display: grid;
  }
} */

.form-input:focus {
  border-color: #e6e6e6;
}

.icon-point {
  margin-right: 5px;
}

@media (max-width: 992px) {
  .icon-point {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  .img-center {
    display: flex;
    justify-content: center;
  }
}

.isShowPassword.fa-eye {
  position: absolute;
  top: 60%;
  right: 5%;
  cursor: pointer;
}

.isHidePassword.fa-eye-slash {
  position: absolute;
  top: 60%;
  right: 5%;
  cursor: pointer;
}

.insuranceLink {
  list-style-type: none;
}

.mainpageImage {
  background-image: url('../img/bg-specialforme1.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin-top: -66px;
}

.navbar-border {
  background-color: #fff;
  border-color: #e7edf5;
  border-right-style: solid;
}

.nav-header {
  background: #fff;
  padding: 33px 25px;
}

.nav > li > a {
  color: #053985;
  padding: 14px 20px 13px 8px;
}

.nav > li.active {
  border-left: 4px solid #e7edf5;
}

.nav > li.active > a {
  background: #053985;
  opacity: 0.8;
}

.nav .nav-second-level {
  background: #fff;
}

.nav-second-level li a {
  padding-left: 20px !important;
}

.pointer {
  cursor: pointer;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fbfdff;
}

.text-darkblue {
  color: #039;
}

.text-green {
  color: #28a745;
}

.text-orange {
  color: #E04403;
}

.text-underline-black:hover {
  text-decoration:underline;
  text-decoration-color: #000000;
}

.text-underline-darkblue {
  text-decoration: underline;
}

.text-underline-darkblue:hover {
  color: #039;
  text-decoration-color: #039;
}

.text-underline-orange {
  text-decoration: underline;
}

.text-underline-orange:hover {
  color: #e04403;
  text-decoration-color: #e04403;
}

@media screen and (max-width: 576px) {
  .navbar-expand .container {
    display: block !important;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .isShowPassword.fa-eye {
    right: 9%;
  }
  .isHidePassword.fa-eye-slash {
    right: 9%;
  }
}

.google-maps {
  height: 50vh;
  width: 100%;
}

.list-group{
  max-height: 250px;
  margin-bottom: 10px;
  overflow-y:auto;
}

.list-group-item:hover {
  background-color: darkgray;
  color: white;
}

.list-group-item > .fa-chevron-right {
  display: none;
}

.list-group-item:hover > .fa-chevron-right {
  display: inline;
  text-align: center;
}

.list-group::-webkit-scrollbar {
  width: 8px;
}

.list-group::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
.list-group::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

.list-group::-webkit-scrollbar-thumb:hover {
  background: grey; 
}

#page-wrapper {
  width: none !important;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}

.pagination > .activePage > a {
  cursor: pointer;
  z-index: 2;
  background-color: #a1a1a1;
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}

.pagination > .activePage > a {
  cursor: pointer;
  z-index: 2;
  background-color: #a1a1a1;
  color: white;
}

.message-history{
  height: 400px;
  overflow-y: scroll;
}

.notFoundImg {
  background-color: #f7f7f7 !important;
}

.bg-softgreen {
  background-color: #ccf1cf;
}

.br-10 {
  border-radius: 10px;
}

.bg-blue {
  background-color: #c0f1ff;
}

@media screen and (min-width: 992px) {
  .navbar-design2 {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .navbar-design1 {
    display: none;
  }
}
